import React, { useState } from 'react';
import { useSpring, animated, interpolate, config } from 'react-spring';
import styled from 'styled-components';

const StyledPath = styled(animated.path)`
    fill:none;
    ${props => props.active ? `stroke:#fff` : `stroke:#000`};
    stroke-width: 5;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit: 10;
    transform-origin: 57.25% 50%;
`

export default function Hamburger(props) {

    // const [active, setActive] = useState(false);

    const styles = useSpring({
        rZ: props.active ? 45 : 0,
        config: props.active ? config.stiff : config.default
    });

    // const handleClick = () => {
    //     setActive(state => !state);
    // }

    const path1 = ['M8 60.6h84.5', 'M19.9 19.9l59.7 59.8'];

    const path2 = ['M7.5 39.4H92', 'M19.9 79.7l59.7-59.8'];

    const darkColor = "#060606";
    const lightColor = "#D8DBF3";

    return (
        <>
            {!props.active ? <svg width="26"
                height="26"
                viewBox="0 0 26 26"
                xmlns="http://www.w3.org/2000/svg">
                <g stroke={props.dark ? darkColor : lightColor}
                    strokeWidth="3.2"
                    fill="none"
                    fillRule="evenodd">
                    <path d="M10.256 1.6c-3.01 0-4.19.228-5.409.88A5.67 5.67 0 0 0 2.48 4.846C1.828 6.065 1.6 7.246 1.6 10.256v5.488c0 3.01.228 4.19.88 5.409a5.67 5.67 0 0 0 2.367 2.368c1.218.651 2.399.879 5.409.879h5.488c3.01 0 4.19-.228 5.409-.88a5.67 5.67 0 0 0 2.368-2.367c.651-1.218.879-2.399.879-5.409v-5.488c0-3.01-.228-4.19-.88-5.409a5.67 5.67 0 0 0-2.367-2.368c-1.218-.651-2.399-.879-5.409-.879h-5.488z" />
                    <path d="M6.5 9.75h7.313" />
                    <path d="M6.5 15.438h13.813" />
                </g>
            </svg> :

                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 26 26"
                    width="26"
                    height="26">
                    <g fill="none"
                        fillRule="evenodd"
                        stroke={lightColor}
                        strokeWidth="3.2">
                        <path d="M10.256 1.6c-3.01 0-4.19.228-5.409.88A5.67 5.67 0 0 0 2.48 4.846C1.828 6.065 1.6 7.246 1.6 10.256v5.488c0 3.01.228 4.19.88 5.409a5.67 5.67 0 0 0 2.367 2.368c1.218.651 2.399.879 5.409.879h5.488c3.01 0 4.19-.228 5.409-.88a5.67 5.67 0 0 0 2.368-2.367c.651-1.218.879-2.399.879-5.409v-5.488c0-3.01-.228-4.19-.88-5.409a5.67 5.67 0 0 0-2.367-2.368c-1.218-.651-2.399-.879-5.409-.879h-5.488z" />
                        <path d="M17.894 7.994l-9.767 9.767" />
                        <path d="M7.68 8.18l9.767 9.767" />
                    </g>
                </svg>
            }
        </>
    );
}
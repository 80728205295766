import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import tw from 'tailwind.macro'
import styled from 'styled-components';
import { useSpring, useTransition, animated, interpolate, config } from 'react-spring';

const Wrapper = styled.div`
    ${tw`cursor-pointer block relative overflow-hidden outline-none z-0`};
    ${props => props.small ? tw`px-2 py-1` : tw`px-6 py-1`};
    `

const Text = styled(animated.div)`
    ${tw`mb-0 text-grey font-black tracking-tight font-primary`};
    will-change:transform;
    ${props => props.small ? tw`text-sm` : tw`text-normal`};
`

const StyledExtLink = styled.a`
    ${tw`absolute h-full w-full z-10 pin-t pin-l`};
`
const StyledLink = styled(Link)`
    ${tw`absolute h-full w-full z-10 pin-t pin-l`};
`

const LineOne = styled(animated.div)`
    ${tw`absolute w-full opacity-50 pin-b bg-grey z-back`};
    height: 16%;
    // width: 61.8%
`
const LineTwo = styled(animated.div)`
    ${tw`absolute w-full opacity-50 pin-b bg-blue z-back`};
    height: 20%;
    // width: 61.8%
`
const BgBar = styled(animated.div)`
    ${tw`absolute w-full h-full pin-l pin-t z-back`};
    ${props => props.isOnDark ? tw`bg-grey-dark` : tw`bg-soft-grey`}
    will-change: transform;
    `
const StartBar = styled(animated.div)`
    ${tw`absolute w-2 h-full pin-l pin-t z-back`};
    ${props => props.isOnDark ? tw`bg-grey-dark` : tw`bg-soft-grey`}

`

export default function CustomLink(args) {
    const [active, setActive] = useState(false);

    const barTransitions = useTransition(active, null, {
        from: { x: -90 },
        enter: { x: 0 },
        leave: { x: 100 },
    })

    const textProps = useSpring({ x: active ? 2 : 0 });


    return (
        <Wrapper {...args}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            onTouchStart={() => setActive(true)}
            onTouchEnd={() => setActive(false)}
            onClick={() => {
                if(!args.external){
                    navigate(`${args.to}`);
                }
            }}
        >
            {args.external &&

                <StyledExtLink href={args.to} target="_blank"></StyledExtLink>}
            {args.internal &&
                <StyledLink to={args.to}></StyledLink>
            }

            <Text style={{ transform: textProps.x.interpolate(x => `translate3d(${x}%, 0,0)`) }}>
                {args.children}
            </Text>

            {barTransitions.map(({ item, key, props }) => item && <BgBar isOnDark={args.isOnDark} key={key} style={{ transform: props.x.interpolate(x => `translate3d(${x}%, 0, 0)`) }} />)}
            <StartBar isOnDark={args.isOnDark} />
        </Wrapper>
    );
}

// export default function CustomLink (props){

//     const [active, setActive] = useState(false);
//     const LineOneProps = useSpring({ from: {y: 0}, to:{y: active ? -700 : 0}, config: active ? config.stiff : config.default});
//     const LineTwoProps = useSpring({ from: {y: 100}, to:{y: active ? 0 : 100}, delay: active? 100: 0 });
//     const TextProps = useSpring({ y: active ? -10 : 0 });

//     return(
//         <Wrapper className={props.className} 
//                 onMouseEnter={() =>setActive(true)}
//                 onMouseLeave={()=> setActive(false)}>
//             {props.external && 

//                 <StyledExtLink href={props.to} target="_blank"></StyledExtLink>}
//             {props.internal &&
//                 <StyledLink to={props.to}></StyledLink>
//             }

//             <Text style={{
//                     transform: TextProps.y.interpolate(y => `translate3d(0, ${y}%, 0)`)
//                 }}>
//                 {props.children}
//             </Text>
//             <LineOne 
//                 style={{
//                     transform: LineOneProps.y.interpolate(y => `translate3d(0, ${y}%, 0)`)
//                 }}
//             />
//             <LineTwo
//                 style={{
//                     transform: LineTwoProps.y.interpolate(y => `translate3d(0, ${y}%, 0)`)
//                 }}
//             />
//         </Wrapper>
//     );
// }


import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useSpring, animated, interpolate, config, useTransition } from 'react-spring';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';

const Wrapper = styled.div`
    ${tw`bg-transparent relative z-0 mr-32 cursor-pointer`};
    // height:  ${p => p.feed ? '16rem' : '24rem'};
    // width: ${p => p.feed ? '100%' : '18rem'};
    height: 30rem;
    width: 28rem;
    box-sizing: border-box;
    @media(min-width : 992px){
        width: 18rem;
        height: 22rem;
    }
    border-radius: 0.809rem;
    perspective: 3000px;
    box-shadow: 0 5px 15px hsla(234, 15%, 80%, 0.3);
`

const ImageWrapper = styled.div`
    border-radius: 0.809rem;
    ${tw`relative w-full h-full z-0 overflow-hidden`};
`

const InfoBox = styled(animated.div)`
    ${tw`absolute z-10 w-full bg-white px-6 py-6`};
    top: 70%;
    min-height: 38.2%;
    border-radius: 0.809rem;
    box-shadow: 0 10px 30px hsla(234, 15%, 80%, 0.6);

`
const AnimatedImg = animated(Img);

const Title = styled.p`
    ${tw`font-primary font-light text-grey-dark leading-tight`};
    font-size: 1.2rem;
    letter-spacing: 0.01em;
`
const Author = styled(animated.p)`
    ${tw`text-sm truncate text-grey-light font-light ml-2`};
`

export default function BlogCard(props) {

    const [hovering, setHovering] = useState(false);
    const styles = useSpring({
        x: hovering ? -10 : 0,
        s: hovering ? 1.05 : 1,
        authorOpacity: hovering ? 0 : 1,
        readOpacity: hovering ? 1 : 0,
        config: config.default
    });

    const InfoBoxStyles = useSpring({
        x: hovering ? -10 : 0,
        config: config.stiff,
    })

    const trans = useTransition(hovering, null, {
        from: { opacity: 0, transform: `translate3d(0, -10px, 0 )` },
        enter: { opacity: 1, transform: `translate3d(0, 0, 0 )` },
        leave: { opacity: 0, transform: `translate3d(0, 10px, 0 )` },
        unique: true,
    })

    return (
        <Wrapper
            {...props}
            onTouchStart={() => setHovering(true)}
            onTouchEnd={() => setHovering(false)}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={() => navigate(`/blog/${props.link}/`)}>

            <ImageWrapper>
                <AnimatedImg fluid={props.image}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        transform: styles.s.interpolate(s => `scale(${s})`),
                    }} />
            </ImageWrapper>

            <InfoBox style={{
                transform: InfoBoxStyles.x.interpolate(y => `translate3d(0, ${y}%, 20px)`),
            }}>
                <Title>{props.title}</Title>
                <Author style={{ opacity: styles.authorOpacity }}>
                    {props.author.name}
                    {/* <strong>{props.author.name.split(' ')[0]}</strong> | {props.author.title.split(',')[0]} */}
                </Author>
                {/* {trans.map(({ item, key, args }) => 
                item
                ? ( <Author style={args}>
                    <strong>{props.author.name.split(' ')[0]}</strong> | {props.author.title.split(',')[0]}
                </Author>)
                : ( <Author style={args}>
                    Read Now
                </Author>)
            )} */}


            </InfoBox>
        </Wrapper>
    );
}


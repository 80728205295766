import React, { useState } from 'react';
import { useSpring, useTrail, useRef, animated, interpolate, config } from 'react-spring'
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { navigate } from 'gatsby';

import Row from '../Row';

const Wrapper = styled(animated.div)`
    ${tw`fixed flex items-center w-screen bg-black pin-t pin-x z-400`};
    height: 90vh;
    border-radius: 0 0 32px 32px;
    will-change: transform;
`
const MenuList = styled.ul`
    ${tw`w-full list-reset m-0`};
`

const MenuListItem = styled(animated.li)`
    span{
        ${tw`-ml-2 font-primary font-black tracking-tight my-2 py-4 px-4 text-150gr text-grey cursor-pointer`};
    }
    ${tw`text-gr font-light text-grey opacity-20`};
    &:hover{
        background: hsla(234,50%, 66%, 0.15);
    }
`

export default function Menu(props) {

    const styles = useSpring({
        y: props.active ? 0 : -100,
        config: { tension: 300, friction: 40, mass: 1 }
    });

    const items = [
        'About',
        'Work',
        'Blog',
        'Contact',
    ]
    // const itemsTrail = useRef();
    const trail = useTrail(items.length, {
        y: props.active ? 0 : -50,
        opacity: props.active ? 1 : 0,
        delay: props.active ? 500 : 0,
        from: { y: -50, opacity: 0 },
        config: props.active ? config.stiff : config.gentle

        // ref: itemsTrail
    })

    const handleNavigate = ((page) => {
        let to = page.toLowerCase();
        navigate(`/${to}/`);
        debugger;
    });


    return (
        <Wrapper
            style={{
                transform: styles.y.interpolate(y => `translate3d(0, ${y}vh, 0)`),
            }}>

            <MenuList>

                {trail.map(({ y, opacity }, index) => (
                    <MenuListItem
                        key={index[index]}
                        style={{
                            transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
                            opacity
                        }}>
                        <Row onClick={() => handleNavigate(items[index])}>
                            0{index}<span>{items[index]}</span>
                        </Row>
                    </MenuListItem>
                ))}
            </MenuList>


        </Wrapper>
    );

}

Menu.defaultProps = {
    active: false,
}
import React, { useState } from 'react';
import { useSpring, useTransition, animated, interpolate, config } from 'react-spring';
import styled from 'styled-components';
import tw from 'tailwind.macro'
import { Link } from 'gatsby';

const Wrapper = styled.div`
    ${tw`relative overflow-hidden z-10 w-full flex items-center justify-center text-center bg-soft-grey cursor-pointer hover:text-white`};
    will-change: transform box-shadow;
    border-radius: 0.5rem;
    ${props => props.large && tw`px-12 py-8`};
    ${props => props.small && tw`px-2 py-1 text-sm`};
    &:hover{
        box-shadow: 0 10px 30px hsla(234, 11%, 15%, 0.3);
        transform: translate3d(0,0,10px);
    }
`
const BlueFiller = styled(animated.div)`
    ${tw`absolute pin-x pin-y rounded-full bg-brand-blue opacity-70`};
    z-index: 1;
    height: 10rem;
    width: 10rem;
    will-change: transform;
    // border-radius: 1000px;
`
const RedFiller = styled(animated.div)`
    ${tw`absolute rounded-full z-0 opacity-50`};
    will-change: transform;
    height: 10rem;
    width: 10rem;
    // top: 0;
    right: -60%;
    background: red;
`
const Children = styled.div`
    ${tw`relative z-10 font-medium`};
`

export default function Button(props) {
    const [active, setActive] = useState(false);
    const bprops = useSpring({ from: { y: 100, s: 0 }, to: { y: active ? 0 : 100, s: active ? 1 : 0 }, config: config.default });
    const rprops = useSpring({ from: { s: 0 }, to: { s: active ? 1 : 0 }, delay: active ? 100 : 0 });
    const handleHover = (bool) => {
        setActive(bool);
    }
    return (
        <Wrapper {...props}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            onTouchStart={() => handleHover(true)}
            onTouchEnd={() => handleHover(false)}
            onClick={props.onClick}>
            {/* <Link css={tw`absolute h-full w-full z-10 pin-l pin-t underline-none`} to={props.to} /> */}
            <Children>
                {props.children}
            </Children>
            <BlueFiller style={{
                // transform: bprops.y.interpolate(y => `translate3d(0, ${y}%, 0)`)
                transform: bprops.s.interpolate(s => `scale(${s}) translate3d(-25%, -25%, 0)`),
                transformOrigin: '50% 50%'
            }} />
            <RedFiller style={{
                transform: rprops.s.interpolate(s => `scale(${s}) translate3d(-25%, -25%, 0)`),
                transformOrigin: '50% 50%'
            }}>
            </RedFiller>
            {/* <RedFiller style={{transform: rprops.y.interpolate(y => `translate3d(0, ${y}%, 0)`)}}/> */}
        </Wrapper>
    );
}
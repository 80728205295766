import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useStaticQuery } from 'gatsby';
import MagicGrid from 'magic-grid-react';

import * as Gs from '../globals/styledComponents.js';

import Layout from '../components/layout';
import Nav from '../components/Nav';
import Row from '../components/Row';
import BlogCard from '../components/utils/BlogCard';
import Footer from '../components/footer.js';
import SEO from '../components/seo';

/*
* Styled Components
*/

const SectionOne = styled.section`
    ${tw`w-full bg-grey-lightest flex flex-wrap items-center`};
    height: 61.8vh;
    border-radius: 0 0 3.236rem 3.236rem;
`
const FeedSection = styled.section`
    ${tw`w-full min-h-screen py-16`};
`

const DropDown = styled.select`
    ${tw`text-dark-grey appearance-none py-6 px-8 bg-transparent outline-none text-gr font-medium`};
    options{
        ${tw`text-dark-grey text-base`};
    }
`


export default function Blog(props) {

    const data = useStaticQuery(graphql`
        query{
            posts : allContentfulBlogPost{
                edges{
                node{
                    slug
                    title
                    thumbnailImage{
                        title
                        assets{
                            fluid(quality: 100){
                            ...GatsbyContentfulFluid_withWebp
                            }
                        }
                    }
                    author{
                    name
                    }
                }
                }
            }
        }
    `)

    return (
        <Layout withDarkMenu>
            <SEO
                title="Work that's Clearcut"
                keywords={[`Clearcut`, `Design`, `Product Design`, 'Product Development', 'Product Marketing', 'Web Design', 'UX Design', 'UI Design', 'React Developers', 'Digital Design Agency', 'Full service creative agency', 'Clearcut Agency']} />
            <SectionOne>
                <Row css={`${tw`px-12`}`}>

                    <div css={tw`w-full lg:w-gr2/3`}>
                        <Gs.HeroHeading css={tw`-ml-1 leading-normal`}>
                            Blog.
                        </Gs.HeroHeading>
                        <Gs.PreTitle css={tw`m-0`}>
                            Our Thoughts, Opinions, News, and Good Intentions.
                        </Gs.PreTitle>
                    </div>

                </Row>
            </SectionOne>

            <FeedSection>
                {/* <Row justifyBetween>
                    <div css={tw`w-1/3`}>
                        <DropDown>
                            <option value="Everything" selected>Everything</option>
                            <option value="Branding" >Branding</option>
                            <option value="Product" >Product</option>
                        </DropDown>
                    </div>
                    
                </Row> */}

                <Row css={`${tw`max-w-screen md:max-w-s-row`}`}>
                    <div css={`${tw`w-full flex flex-wrap -mx-4 overflow-hidden py-12`}`}>
                        {data.posts.edges.map((item, index) =>
                            <div
                                css={`${tw`w-full md:w-1/2 lg:w-1/3 lg:px-4 my-10`}`}
                                style={{ marginTop: `${(index + 1) % 2 == 0 ? '3rem' : '0'}` }}>
                                <BlogCard
                                    css={`${tw`w-full my-12`}; width: 100% !important;`}
                                    key={index}
                                    link={item.node.slug}
                                    title={item.node.title}
                                    author={item.node.author}
                                    image={item.node.thumbnailImage.assets[0].fluid} />

                            </div>

                        )}
                    </div>
                </Row>
            </FeedSection>
            <Footer />

        </Layout>
    );
}
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { navigate, navigateTo } from 'gatsby';
import { useSpring, animated, interpolate } from 'react-spring';

import Row from './Row';
import Logo from './svg/Logo';
import Button from './utils/Button';
import Hamburger from './svg/Hamburger';

const Wrapper = styled(animated.div)`
  ${tw`fixed pin-t pin-x w-screen z-500 pt-8 pb-4 lg:py-4`};
  will-change: transform;
`
const NavBg = styled(animated.div)`
  ${tw`absolute pin-b h-full w-full bg-white z-10`};
  will-change: transform opacity;
`

const MenuButtonWrapper = styled.div`
    ${tw`w-auto flex items-center text-center justify-center px-2 py-1 cursor-pointer hover:opacity-60`};
    p{
        ${tw`hidden lg:flex font-primary font-medium text-grey text-sm mr-2 mb-1`};
    }
`

export default function Nav(props) {

    const [isContactPage, setContactPage] = useState(false);

    const ySpring = useSpring({
        from: {
            y: -100,
            bgY: -100
        },
        to: {
            y: props.isScrolled ? 0 : 20,
            bgY: props.isScrolled ? 0 : -200
        }
    });

    const MenuButton = (props) => {
        return (
            <MenuButtonWrapper onClick={props.handleMenuClick}>
                <p>Menu</p>
                <Hamburger active={props.isOnMenu} />
            </MenuButtonWrapper>
        );
    }

    useEffect(() => {
        console.log(`current page: ${window.location.pathname}`);
        if (window.location.pathname == '/contact/' || window.location.pathname == '/contact') {
            setContactPage(true);
        }
    }, [])

    const contactClick = () => {
        navigate('/contact');
        // navigateTo('/contact/');
    }

    return (
        <Wrapper style={{
            transform: ySpring.y.interpolate(y => `translate3d(0,${y}px,0)`),
        }}>
            <Row justifyBetween
                css={tw`px-8 lg:px-2 relative z-100`}>
                <div css={tw`w-auto`}>
                    <Logo height="28"
                        dark={props.darken || props.isOnMenu}
                        onClick={() => navigate('/')} />
                </div>
                <div css={tw`flex-1 flex flex-wrap items-center text-right justify-end`}>

                    {(!props.isOnMenu && !isContactPage) && (<div css={tw`w-auto mx-1 lg:mx-8`}>
                        <Button onClick={contactClick} css={tw`hidden md:flex px-12 py-2`} small>
                            Say Hello
                        </Button>
                    </div>)}

                    <div css={tw`border-l-2 border-grey-light border-solid border-0 lg:ml-2`}>
                        <MenuButtonWrapper onClick={props.handleMenuClick}>
                            <p css={tw`m-0`}>Menu</p>
                            <Hamburger dark={props.darken} active={props.isOnMenu} />
                        </MenuButtonWrapper>
                    </div>
                </div>
            </Row>

            <NavBg
                style={{
                    transform: ySpring.bgY.interpolate(y => `translate3d(0, ${y}%, 0)`),
                    opacity: props.isOnMenu ? 0 : 1,
                }} />

        </Wrapper>
    );
}


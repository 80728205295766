import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import tw from 'tailwind.macro';


export const modularScale = 1.1414 * 0.809;

export const HeroHeading = styled("h1")`
  ${tw`font-primary font-black`}
  letter-spacing: -0.07em;
  line-height: 0.809;
  color: #00010E;
  font-size: ${modularScale * 5}rem;
`

export const SubHeroHeading = styled(HeroHeading)`

`

export const PreTitle = styled("h2")`
  ${tw`font-serif text-gr font-light text-grey mb-2`};
`

export const SectionHeading = styled("h3")`
  ${tw`text-gr text-grey font-primary font-black tracking-tight`};
`

export const SectionSubHeading = styled(SectionHeading)`
  ${tw`font-primary font-light text-grey-lighter mb-4`};
`

// export const Paragraph = styled.p`
//   ${tw`font-light text-grey-light mt-4`};
//   letter-spacing: 0.62px;
//   line-height: 1.618;
//   ${props => props.big && tw`text-xl`}
// `

export const Paragraph = styled.p`
  ${tw`font-serif text-grey mt-4`};
  letter-spacing: 0.62px;
  line-height: 1.618;
  ${props => props.big && tw`text-xl`}
`

export const EndTitle = styled.h3`
  ${tw`font-primary font-semibold text-150gr text-grey-dark`}
  // font-family: SofiaProSemiBold;
  // font-size: 51.78px;
  // color: #33343E;
  // letter-spacing: -1px;
  line-height: 49.27px;
`

export const SectionTitle = styled.h2`
  ${tw`text-2gr tracking-tight font-primary font-black text-grey-dark mb-4`};
  line-height: 0.809;
`

export const SerifTitle = styled.h2`
  ${tw`text-150gr tracking-light font-serif font-light text-grey`};
  line-height: 0.809;
`

export const SectionTitleParagraph = styled.p`
  ${tw`text-gr font-primary font-light text-grey-light`};
`

export const Quotation = styled.p`
  ${tw`text-gr text-grey-dark font-sans font-medium`};
`

export const SectionSubTitle = styled(SectionTitle)`
  ${tw`text-grey-light`};
`

export const ImageTitle = styled.h3`
  // ${tw`font-serif text-gr text-grey tracking-tight font-light`};
  // line-height: 1.539;
  ${tw`font-primary text-2gr text-black font-bold tracking-tight`};
  line-height: 0.809;
`

export const Seperator = styled.div`
  ${tw`w-full flex h-px bg-grey opacity-20`};
`

export const SuperTitle = styled.h1`
  ${tw`font-black font-primary`};
  color: #18181C;
  letter-spacing: -0.04em;
  line-height: 0.809;
  font-size: 4.95rem;
  @media(min-width: 992px){
    font-size: 9.898rem;
  }
`

export const TransitionTitle = styled.h2`
  ${tw`font-medium font-primary`};
  color: #9597A7;
  line-height: 0.809;
  letter-spacing: -0.05em;
`


export const QuotationText = styled.p`
  font-family: 'serifa';
  ${tw`font-serif font-light text-grey`};
  font-size: ${props => props.small ? `${modularScale * 0.8}rem` : `${modularScale * 1.618}rem`};
  letter-spacing: 0;
  line-height: 1.414;
  @media(min-width: 992px){
    font-size: ${props => props.small ? `${modularScale}rem` : `${modularScale * 2}rem`};
  }
`
/*
* Global Styles
*/

export const GlobalStyle = createGlobalStyle`
  :root{
    @media(min-width: 992px){
      font-size: 16px;
    }
    font-size: 13px;
  }
  body{

  }
  h1, h2, h3, h4, h5, h6{
    ${tw`mb-8`};
    margin-bottom: 2rem;
    line-height: 0.809;
    @media (max-width: 320px) {
      font: 80%;
    }
  }
  p{
    ${tw`font-secondary font-light text-grey my-6`};
    letter-spacing: 0.006em;
    @media(min-width: 768px){
      font-size: 1rem;
    }
    font-size: 1.414rem;
    line-height: 1.618;
  }
  h1{
    ${tw`font-black font-primary `};
    color: #060606;
    letter-spacing: -0.05em;
    margin: 0;
    font-size: ${modularScale * 6}rem;
  }
  h2{
    ${tw`font-black font-primary`};
    color: #33343E;
    letter-spacing: -0.02em;
    margin: 0;
    font-size: ${modularScale * 5}rem;
  }
  h3{
    ${tw`font-black font-primary`};
    margin-bottom: ${modularScale}rem;
    color: #33343E;
    letter-spacing: -0.01em;
    line-height: 0.809;
    font-size: ${modularScale * 4}rem;
  }
  h4{
    ${tw`font-black font-primary`};
    color: #33343E;
    letter-spacing: -0.03em;
    margin: 0;
    line-height: 0.809;
    font-size: ${modularScale * 2}rem;
    @media(min-width: 992px){
      font-size: ${modularScale * 3}rem;
      margin: 0;
    }
    font-size: ${modularScale * 3}rem;

  }
  h5{
    ${tw`font-semibold font-primary`};
    font-size: ${modularScale * 2}rem;
    color: #33343E;
    letter-spacing: -1.3px;
    margin: 0;
  }
  h6{
    ${tw`font-semibold font-primary`};
    font-size: ${modularScale}rem;
    color: #33343E;
    letter-spacing: 0;
    margin: 0;
    line-height: 0.809;
  }
  hr{
    background: hsla(234, 15%, 80%, 0.5);
    height: 0.01em;
    width: 100%;
  }
`
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import styled from 'styled-components';
import tw from 'tailwind.macro';
import {useSpring, config, animated, interpolate} from 'react-spring';

import Row from "./Row";
import Logo from "./svg/Logo";
import CustomLink from './utils/CustomLink';
import MenuShape from './svg/MenuShape';
import Button from './utils/Button';
import MenuButton from './utils/MenuButton';

import { MenuContext } from '../context/menu-context.js';

const Wrapper = styled(animated.div)`
  ${tw`fixed pin-t pin-x w-screen z-500 py-2 lg:py-4`};
`
const HeaderLogo = styled(Logo)`
`
const ContactButton = styled.button`
  ${tw`appearance-none outline-0 py-2 md:px-8 bg-black text-white font-primary font-medium shadow-none rounded-lg outline-none`}
`

const NavBg = styled(animated.div)`
  ${tw`absolute pin-b h-full w-full bg-white z-10`};
`


function Header(props){
  // const ySpring = useSpring({ 
  //   from:{
  //     y: -100, 
  //     bgY: -100
  //   }, 
  //   to:{
  //     y: 0, 
  //     bgY: props.active ? 0 : -100  
  //   }
  // });
  // return(

  //         <Wrapper style={{
  //               transform: ySpring.y.interpolate(y => `translate3d(0,${y}px,0)`)
  //             }}>

  //             <Row css={tw`px-8 lg:px-2 relative z-100`} justifyBetween>
  //               <div css={tw`w-auto`}>
  //                 <Logo height="28" isOnDark={active}/>
  //               </div>
  //               <div css={tw`flex-1 flex flex-wrap items-center text-right justify-end`}>

  //                     {!active && (<div css={tw`w-24 mx-1 lg:mx-8`}>
  //                       <Button css={tw`hidden md:flex`} small 
  //                         to='/contact/'>
  //                         Contact
  //                       </Button>
  //                     </div>)}

  //                 <div css={tw`border-l-2 border-grey-light border-solid border-0 lg:ml-2`}>
  //                   <MenuButton onMenuButtonClick={props.handleMenuClick}/>
  //                 </div> 
  //               </div>
  //             </Row>
           
  //             <NavBg style={{
  //                       transform: ySpring.bgY.interpolate(y => `translate3d(0, ${y}%, 0)`),
  //                       opacity: active ? 0 : 1,
  //               }}/>            
  //         </Wrapper>
  // )
} 

Header.propTypes = {
  siteTitle: PropTypes.string,
  active: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  active: false,
}

export default Header

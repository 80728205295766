import React, { useState } from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components'

import MenuShape from '../svg/MenuShape';
import Hamburger from '../svg/Hamburger';

const Wrapper = styled.div`
    ${tw`w-auto flex items-center text-center justify-center px-2 py-1 cursor-pointer hover:opacity-60`};
`

const P = styled.p`
    ${tw`hidden lg:flex font-primary font-medium text-grey text-sm mr-2 mb-1`};
`

export default function MenuButton(props) {

    const [isActive, setActive] = useState(false)

    const handleClick = () => {
        setActive(state => !state);
        props.onMenuButtonClick();
    }

    return (
        <Wrapper
            onClick={handleClick}>
            <P active={isActive}>Menu</P>
            {/* <Hamburger active={isActive}/> */}
            {!isActive ? <svg width="26"
                height="26"
                viewBox="0 0 26 26"
                xmlns="http://www.w3.org/2000/svg">
                <g stroke="#060606"
                    strokeWidth="3.2"
                    fill="none"
                    fillRule="evenodd">
                    <path d="M10.256 1.6c-3.01 0-4.19.228-5.409.88A5.67 5.67 0 0 0 2.48 4.846C1.828 6.065 1.6 7.246 1.6 10.256v5.488c0 3.01.228 4.19.88 5.409a5.67 5.67 0 0 0 2.367 2.368c1.218.651 2.399.879 5.409.879h5.488c3.01 0 4.19-.228 5.409-.88a5.67 5.67 0 0 0 2.368-2.367c.651-1.218.879-2.399.879-5.409v-5.488c0-3.01-.228-4.19-.88-5.409a5.67 5.67 0 0 0-2.367-2.368c-1.218-.651-2.399-.879-5.409-.879h-5.488z" />
                    <path d="M6.5 9.75h7.313" />
                    <path d="M6.5 15.438h13.813" />
                </g>
            </svg> :

                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 26 26"
                    width="26"
                    height="26">
                    <g fill="none"
                        fillRule="evenodd"
                        stroke="#D8DBF3"
                        strokeWidth="3.2">
                        <path d="M10.256 1.6c-3.01 0-4.19.228-5.409.88A5.67 5.67 0 0 0 2.48 4.846C1.828 6.065 1.6 7.246 1.6 10.256v5.488c0 3.01.228 4.19.88 5.409a5.67 5.67 0 0 0 2.367 2.368c1.218.651 2.399.879 5.409.879h5.488c3.01 0 4.19-.228 5.409-.88a5.67 5.67 0 0 0 2.368-2.367c.651-1.218.879-2.399.879-5.409v-5.488c0-3.01-.228-4.19-.88-5.409a5.67 5.67 0 0 0-2.367-2.368c-1.218-.651-2.399-.879-5.409-.879h-5.488z" />
                        <path d="M17.894 7.994l-9.767 9.767" />
                        <path d="M7.68 8.18l9.767 9.767" />
                    </g>
                </svg>}

        </Wrapper>
    );
}
import React from 'react';
import tw from 'tailwind.macro'
import styled, { css } from 'styled-components'
import Row from './Row';
import Logo from './svg/Logo';
import CustomLink from './utils/CustomLink';

import { DribbbleLogo, FacebookLogo, InstagramLogo, TwitterLogo, LinkedInLogo } from '../components/svg/SocialLogos.js';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.footer`
    ${tw`relative z-0 bg-black text-grey-lightest`};
    // padding-top: 15%;
    min-height: 62vh;
    &:before{
        content: "";
        background: white;
        height: 3rem;
        width: 100vw;
        // transform: translateY(-2rem);
        box-shadow: 0 20px 40px -10px rgba(0,0,20,0.1);
        border-radius: 0 0 2.4rem 2.4rem;
        display: block;
        // position: absolute;

    }
`
const AddressColumn = styled.div`
    ${tw`md:w-1/3 w-full md:text-right text-left font-secondary`};

`

const SocialLink = styled.a`
    ${tw`md:ml-8 md:mr-0 mr-10`};
    transition: all .2s ease-in-out;
    &:hover{
        transition: all .2s ease-in-out;
        transform: scale(0.9);
    }
`
const SmallPrint = styled.a`
    ${tw`no-underline no-appearance text-brand-bluishgrey opacity-50 text-sm`};
`

const Footer = (props) => (
    <Wrapper>

        <Row justifyBetween css={tw`items-start mt-24`}>

            <div css={`${tw`my-12 lg:my-0 w-full lg:flex-1`}`}>
                <p css={`${tw`font-light text-brand-bluishgrey text-xl opacity-50 m-0`}`}>Got a Project?</p>
                <CustomLink isOnDark internal to='/contact/'>
                    <p css={` ${tw`font-primary font-black text-4xl tracking-tight text-brand-bluishgrey m-0 mt-2 md:mt-0`}`}>
                        Get in Touch
                    </p>
                </CustomLink>
            </div>

            <div css={`${tw`w-full lg:flex-1 my-12 lg:my-0 md:w-1/3 lg:text-right text-left`}`}>

                <Logo isOnDark height={isMobile ? "32" : "48"} />
                <Row css={`${tw`mt-8 md:text-right justify-end md:justify-start px-0 flex-row-reverse`}`}>
                    <SocialLink href="https://dribbble.com/clearcut" target="_blank">
                        <DribbbleLogo height="28" target="_blank" />
                    </SocialLink>
                    <SocialLink href="https://www.instagram.com/clearcutdesignstudio/" target="_blank">
                        <InstagramLogo height="28" target="_blank" />
                    </SocialLink>
                    <SocialLink href="https://twitter.com/ClearcutAgency" target="_blank">
                        <TwitterLogo height="28" target="_blank" />
                    </SocialLink>
                    <SocialLink href="https://www.linkedin.com/company/clearcut-design/" target="_blank">
                        <LinkedInLogo height="28" target="_blank" />
                    </SocialLink>
                    <SocialLink href="https://www.facebook.com/clearcutdesignstudio/" target="_blank">
                        <FacebookLogo height="28" />
                    </SocialLink>
                </Row>

            </div>


        </Row>

        <Row justifyBetween css={`${tw`mt-12 lg:mt-20 flex-col-reverse lg:flex-row`}`}>

            <div css={`${tw`w-full lg:w-1/2 py-4 lg:py-0`}`}>
                <SmallPrint>
                    &copy; Copyright 2019
                </SmallPrint>
            </div>
            <div css={`${tw`w-full lg:w-1/2 lg:text-right py-12 lg:py-0`}`}>
                <SmallPrint>
                    C/o Cuboft Marketing &amp; Software Solutions Pvt. Ltd.<br />
                    <span css={`${tw`my-4 lg:my-0`}`}>CIN: U72200TG2015PTC101100</span>
                </SmallPrint>
            </div>


        </Row>

    </Wrapper>

);

export default Footer;
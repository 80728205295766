/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ metaDescription, description, lang, meta, keywords, title, author, url, image }) {


  // const metaDescription = description;
  const imageUrl = image ? image.file.url : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: "Clearcut Design Studio",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          // content: `https:${imageUrl}`,
          content: 'https://images.ctfassets.net/b62jgkkofmz2/3bwBtNILcQVg0ks1TD3w6b/66c689c64f287d2b8e8b76459642cf49/Generic_Meta_Image_2x.png',
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:card`,
          content: 'summary_large_image',
        },
        {
          name: `twitter:creator`,
          content: `@praneethpike`,
        },
        {
          name: `twitter:site`,
          content: `@clearcutAgency`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https:${imageUrl}`,
        },
        {
          name: `twitter:image:alt`,
          content: `Clearcut SEO Image`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
